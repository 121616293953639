"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OTHER_LOAN_PRESS = exports.DOWNLOAD_RECEIPT_PRESS = exports.CLOSE_TRANSACTION_MODAL_PRESS = exports.INSTALMENT_DELETE_PRESS = exports.INSTALMENT_RECEIPT_PRESS = exports.LOAN_ACTION_PRESS = exports.ACTION_HISTORY_EXPAND_PRESS = exports.HISTORY_BOX_FILTER_PRESS = exports.HISTORY_BOX_ANCHOR = exports.TAP_PAYMENT_DELAY_REASON_BUTTON = exports.PROMISE_TO_PAY_DATE_BUTTON = exports.MENU_DROPDOWN = exports.CONTACTS_DROPDOWN = exports.TOGGLE_TIMER_BUTTON = exports.SEND_FEEDBACK_BUTTON = exports.NAVIGATE_GO_BACK_COMMUNICATION = exports.SEND_SMS_EXPAND_PRESS = exports.SEND_SMS_BUTTON_PRESS = exports.NAVIGATE_SEND_SMS_BUTTON = exports.REGULAR_CALL_EXPAND_PRESS = exports.REGULAR_CALL_START_PRESS = exports.REGULAR_CALL_BUTTON = exports.SET_TRACK_STREAM = exports.UA_DISCONNECTED = exports.UA_CONNECTED = exports.CONFIRMED_HANDLER = exports.ENDED_HANDLER = exports.FAILED_HANDLER = exports.CONNECTING_HANDLER = exports.TAP_REDIAL_FAILED_CALL = exports.TAP_REDIAL_ENDED_CALL = exports.VOIP_CALL_EXPAND_PRESS = exports.END_CALL_CONNECTING = exports.END_CALL_DIALOG_PRESS = exports.HOLD_VOIP_CALL_PRESS = exports.MUTE_VOIP_CALL_PRESS = exports.CONFIRM_ENDING_VOIP_CALL_PRESS = exports.CANCEL_ENDING_VOIP_CALL_PRESS = exports.START_VOIP_CALL = exports.INBOUND_CALL_BUTTON = exports.VOIP_CALL_BUTTON = exports.CHANGE_PASSWORD_BUTTON = exports.LOAN_INFO_EXPAND_PRESS = exports.TOGGLE_DISPLAY_USER_CONTACTS = exports.TOGGLE_VIEW_USER_TICKETS = exports.TOGGLE_CREATE_USER_TICKET = exports.TOGGLE_SEND_USER_SMS = exports.TOGGLE_USER_DOES_NOT_NEED_LOAN = exports.TOGGLE_NOTES_INPUT = exports.CUSTOMER_INFO_EXPAND_PRESS = void 0;
exports.TABLE_ROW_SELECTED = exports.NEW_NOTIFICATION_MESSAGE_EVENT = exports.NEW_MESSAGE_EVENT = exports.VIEW_NOTIFICATION_ITEM = exports.NOTIFICATION_PRESS = exports.VIEW_ALL_NOTIFICATIONS_BUTTON = exports.REQUEST_BROWSWER_NOTIFICATION = exports.CANCEL_FATAL_SELECTION = exports.PROCEED_FATAL_SELECTION = exports.COMMENT_CALL_DETAIL_RECORD = exports.NAVIGATE_AUDITED_CDR_BUTTON = exports.DISPLAY_CALL_DETAIL_RECORD_MODAL = exports.AUDIT_CALL_RECORD_DETAIL_BUTTON = exports.SELECT_CALL_DATE_BUTTON = exports.PLAY_CALL_DETAIL_RECORD = exports.CLEAR_CALL_FILTER_BUTTON = exports.CALL_FILTER_BUTTON = exports.SELECT_CALL_DETAIL_RECORD = exports.DISMISS_MESSAGE_MODAL = exports.TOGGLE_SCHEDULE_MODE_BUTTON = exports.OFFICER_STATUS_PAUSE_BUTTON = exports.SCHEDULES_FILTER_BUTTON = exports.NAVIGATE_DASHBOARD_BUTTON = exports.CONFIRM_COMPLETE_SCHEDULE_BUTTON = exports.COMPLETE_SCHEDULE_BUTTON = exports.CONFIRM_START_SCHEDULE_BUTTON = exports.START_SCHEDULE_BUTTON = exports.SEARCH_SCHEDULE_BUTTON = exports.GET_SCHEDULE = exports.RECYCLE_SCHEDULE = exports.CONFIRM_CALL_LATER = exports.CALL_LATER_BUTTON = exports.NAVIGATE_HOME_BUTTON = exports.CONFIRM_NEXT_BUTTON_PRESS = exports.NEXT_SCHEDULE_PRESS = exports.LOGIN_PRESS = exports.TAB_PRESS = exports.RADIO_CHOICE_PRESS = exports.ADD_NOTES_PRESS = exports.GO_TO_ITEMS_PAGE_BUTTON = exports.GET_PREVIOUS_ITEMS_PAGE = exports.GET_NEXT_ITEMS_PAGE = exports.PAGE_NAVIGATE = exports.PREVIOUS_SCHEDULES_NAVIGATE = exports.NAV_SEARCH_NAVIGATE = exports.NAV_PROFILE_NAVIGATE = exports.NAV_ANCHOR_PRESS = exports.LOAN_ACTION_CONFIRMATION_CLOSE_PRESS = exports.LOAN_ACTION_CONFIRMATION_SAVE_PRESS = exports.LOAN_ID_PRESS = void 0;
exports.SELECT_TICKET_DATE_BUTTON = exports.FILTER_TICKET_BUTTON = exports.UPDATE_TICKET_BUTTON = exports.CREATE_TICKET_BUTTON = exports.COPY_TO_CLIPBOARD = void 0;
// CUSTOMER INFO EVENTS
exports.CUSTOMER_INFO_EXPAND_PRESS = 'tap_customer_info_expand';
exports.TOGGLE_NOTES_INPUT = 'tap_add_notes_icon_button';
exports.TOGGLE_USER_DOES_NOT_NEED_LOAN = 'tap_user_does_not_need_loan_button';
exports.TOGGLE_SEND_USER_SMS = 'tap_send_user_sms_button';
exports.TOGGLE_CREATE_USER_TICKET = 'tap_create_user_ticket_button';
exports.TOGGLE_VIEW_USER_TICKETS = 'tap_view_user_tickets';
exports.TOGGLE_DISPLAY_USER_CONTACTS = 'tap_view_user_contacts';
// LOAN INFO EVENTS
exports.LOAN_INFO_EXPAND_PRESS = 'tap_loan_info_expand';
// CHANGE PASSWORD EVENTS
exports.CHANGE_PASSWORD_BUTTON = 'tap_change_password_submit';
// COMMUNICATION BOX EVENTS
// // VOIP EVENTS
exports.VOIP_CALL_BUTTON = 'tap_voip_call_button_navigate';
exports.INBOUND_CALL_BUTTON = 'tap_inbound_call_button_navigate';
exports.START_VOIP_CALL = 'tap_start_voip_call_button';
exports.CANCEL_ENDING_VOIP_CALL_PRESS = 'tap_cancel_ending_voip_button';
exports.CONFIRM_ENDING_VOIP_CALL_PRESS = 'tap_confirm_ending_voip_button';
exports.MUTE_VOIP_CALL_PRESS = 'tap_mute_voip_button';
exports.HOLD_VOIP_CALL_PRESS = 'tap_hold_voip_button';
exports.END_CALL_DIALOG_PRESS = 'tap_end_voip_dialog_button';
exports.END_CALL_CONNECTING = 'tap_end_call_connecting_button';
exports.VOIP_CALL_EXPAND_PRESS = 'tap_voip_call_expand';
exports.TAP_REDIAL_ENDED_CALL = 'tap_ended_call_redial';
exports.TAP_REDIAL_FAILED_CALL = 'tap_failed_call_redial';
exports.CONNECTING_HANDLER = 'voip_connecting_handler';
exports.FAILED_HANDLER = 'voip_failed_handler';
exports.ENDED_HANDLER = 'voip_ended_handler';
exports.CONFIRMED_HANDLER = 'voip_confirmed_handler';
// // UA EVENTS
exports.UA_CONNECTED = 'voip_ua_connected';
exports.UA_DISCONNECTED = 'voip_ua_disconnected';
exports.SET_TRACK_STREAM = 'voip_set_track_stream';
// // REGULAR CALL EVENTS
exports.REGULAR_CALL_BUTTON = 'tap_regular_call_navigate';
exports.REGULAR_CALL_START_PRESS = 'tap_regular_call_start_button';
exports.REGULAR_CALL_EXPAND_PRESS = 'tap_regular_call_expand';
// // SEND SMS EVENTS
exports.NAVIGATE_SEND_SMS_BUTTON = 'tap_send_sms_navigate';
exports.SEND_SMS_BUTTON_PRESS = 'tap_send_sms_button';
exports.SEND_SMS_EXPAND_PRESS = 'tap_send_sms_expand';
// // OTHER COMMUNICATION BOX EVENTS
exports.NAVIGATE_GO_BACK_COMMUNICATION = 'tap_go_back_communication_navigate';
exports.SEND_FEEDBACK_BUTTON = 'tap_send_feedback_button';
exports.TOGGLE_TIMER_BUTTON = 'tap_toggle_timer_button';
exports.CONTACTS_DROPDOWN = 'tap_contacts_dropdown_button';
exports.MENU_DROPDOWN = 'tap_menu_dropdown_button';
exports.PROMISE_TO_PAY_DATE_BUTTON = 'tap_promise_to_pay_date_button';
exports.TAP_PAYMENT_DELAY_REASON_BUTTON = 'tap_payment_delay_reason_button';
// HISTORY BOX EVENTS
exports.HISTORY_BOX_ANCHOR = 'tap_history_box_achor_button';
exports.HISTORY_BOX_FILTER_PRESS = 'tap_history_box_filter_button';
exports.ACTION_HISTORY_EXPAND_PRESS = 'tap_action_history_expand_button';
// LOAN DETAILS EVENTS
exports.LOAN_ACTION_PRESS = 'tap_loan_action_button';
exports.INSTALMENT_RECEIPT_PRESS = 'tap_instalment_receipt_button';
exports.INSTALMENT_DELETE_PRESS = 'tap_instalment_delete_button';
exports.CLOSE_TRANSACTION_MODAL_PRESS = 'tap_close_transaction_modal_button';
exports.DOWNLOAD_RECEIPT_PRESS = 'tap_download_receipt_button';
exports.OTHER_LOAN_PRESS = 'other_loan_button';
exports.LOAN_ID_PRESS = 'tap_loan_id_link_press';
exports.LOAN_ACTION_CONFIRMATION_SAVE_PRESS = 'tap_loan_action_confirmation_save_button';
exports.LOAN_ACTION_CONFIRMATION_CLOSE_PRESS = 'tap_loan_action_confirmation_close_button';
// NAV EVENTS
exports.NAV_ANCHOR_PRESS = 'tap_nav_anchor_button';
exports.NAV_PROFILE_NAVIGATE = 'tap_profile_link_navigate';
exports.NAV_SEARCH_NAVIGATE = 'tap_search_link_navigate';
exports.PREVIOUS_SCHEDULES_NAVIGATE = 'tap_previous_schedules_navigate';
// PAGINATOR EVENTS
exports.PAGE_NAVIGATE = 'tap_page_navigate';
exports.GET_NEXT_ITEMS_PAGE = 'tap_get_next_previous_items_button';
exports.GET_PREVIOUS_ITEMS_PAGE = 'tap_get_previous_items_button';
exports.GO_TO_ITEMS_PAGE_BUTTON = 'tap_go_to_items_page_button';
// NOTES EVENTS
exports.ADD_NOTES_PRESS = 'tap_add_notes_button';
// SMALL COMPONENTS EVENTS
exports.RADIO_CHOICE_PRESS = 'tap_radio_choice_button';
exports.TAB_PRESS = 'tab_button';
// AUTH EVENTS
exports.LOGIN_PRESS = 'tap_login_button';
// HOME EVENTS
exports.NEXT_SCHEDULE_PRESS = 'tap_next_schedule_button';
exports.CONFIRM_NEXT_BUTTON_PRESS = 'tap_confirm_next_schedule_button';
exports.NAVIGATE_HOME_BUTTON = 'tap_profile_home_navigate';
exports.CALL_LATER_BUTTON = 'tap_call_later_button';
exports.CONFIRM_CALL_LATER = 'tap__confirm_call_later_button';
exports.RECYCLE_SCHEDULE = 'recycle_schedule';
exports.GET_SCHEDULE = 'tap_get_schedule';
// SEARCH SCHEDULE EVENTS
exports.SEARCH_SCHEDULE_BUTTON = 'tap_schedule_search_button';
exports.START_SCHEDULE_BUTTON = 'tap_schedule_start';
exports.CONFIRM_START_SCHEDULE_BUTTON = 'tap_schedule_confirm_start';
exports.COMPLETE_SCHEDULE_BUTTON = 'tap_schedule_complete';
exports.CONFIRM_COMPLETE_SCHEDULE_BUTTON = 'tap_schedule_confirm_complete';
exports.NAVIGATE_DASHBOARD_BUTTON = 'tap_search_navigate';
exports.SCHEDULES_FILTER_BUTTON = 'tap_schedules_filter_button';
// PAUSE EVENT
exports.OFFICER_STATUS_PAUSE_BUTTON = 'officer_status_pause_button';
// SCHEDULE MODE
exports.TOGGLE_SCHEDULE_MODE_BUTTON = 'TOGGLE_SCHEDULE_MODE_BUTTON';
// MESSAGE MODAL
exports.DISMISS_MESSAGE_MODAL = 'dismiss_message_modal';
// CALL DETAIL RECORD
exports.SELECT_CALL_DETAIL_RECORD = 'tap_call_detail_record';
exports.CALL_FILTER_BUTTON = 'tap_call_filter_button';
exports.CLEAR_CALL_FILTER_BUTTON = 'tap_clear_call_filter_button';
exports.PLAY_CALL_DETAIL_RECORD = 'tap_call_detail_record_pay';
exports.SELECT_CALL_DATE_BUTTON = 'tap_select_call_date_button';
exports.AUDIT_CALL_RECORD_DETAIL_BUTTON = 'tap_audit_call_record_detail_button';
exports.DISPLAY_CALL_DETAIL_RECORD_MODAL = 'tap_display_call_detail_record';
exports.NAVIGATE_AUDITED_CDR_BUTTON = 'tap_back_to_audited_cdr_button';
exports.COMMENT_CALL_DETAIL_RECORD = 'tap_back_comment_cdr_button';
exports.PROCEED_FATAL_SELECTION = 'tap_proceed_fatal_selection';
exports.CANCEL_FATAL_SELECTION = 'tap_cancel_fatal_selection';
// NOTIFICATION
exports.REQUEST_BROWSWER_NOTIFICATION = 'tap_request_browswer_notification';
exports.VIEW_ALL_NOTIFICATIONS_BUTTON = 'tap_view_all_notifications_button';
exports.NOTIFICATION_PRESS = 'tap_notification_button';
exports.VIEW_NOTIFICATION_ITEM = 'tap_view_notification_item';
exports.NEW_MESSAGE_EVENT = 'new_message';
exports.NEW_NOTIFICATION_MESSAGE_EVENT = 'new_notification_message';
// TABLE EVENTS
exports.TABLE_ROW_SELECTED = 'tap_table_row';
exports.COPY_TO_CLIPBOARD = 'copy_to_clipboard';
// TICKET EVENTS
exports.CREATE_TICKET_BUTTON = 'tap_create_ticket_button';
exports.UPDATE_TICKET_BUTTON = 'tap_update_ticket_button';
exports.FILTER_TICKET_BUTTON = 'tap_filter_ticket_button';
exports.SELECT_TICKET_DATE_BUTTON = 'tap_select_ticket_date_button';
