"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NOT_COMPLETED_STATUSES_CHOICES = void 0;
const schedulesConstants_1 = require("../../features/schedules/schedulesConstants");
exports.NOT_COMPLETED_STATUSES_CHOICES = [
    { value: '', label: 'Select status' },
    { value: schedulesConstants_1.SCHEDULE_STATUS_NOT_STARTED, label: 'Not Started' },
    { value: schedulesConstants_1.SCHEDULE_STATUS_STARTED, label: 'Started' },
    { value: schedulesConstants_1.SCHEDULE_STATUS_RESCHEDULED, label: 'Rescheduled' },
    { value: schedulesConstants_1.SCHEDULE_STATUS_RECYCLE, label: 'Recycle' },
];
