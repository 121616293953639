"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MANUAL_CONFIRMATION_SCREEN = exports.NOTIFICATIONS_SCREEN = exports.CAMPAIGNS_METRICS_SCREEN = exports.CAMPAIGNS_SCREEN = exports.CAMPAIGN_SCREEN = exports.TICKET_DETAIL_SCREEN = exports.TICKET_SCREEN = exports.AUDITED_CDR_SCREEN = exports.AUDIT_SCREEN = exports.QUALITY_ASSURANCE_SCREEN = exports.OFFICER_SCHEDULES_SCREEN = exports.OFFICERS_METRICS_SCREEN = exports.PREVIOUS_SCHEDULES_SCREEN = exports.SEARCH_SCREEN = exports.SCHEDULE_SCREEN = exports.PROFILE_SCREEN = exports.SIGNIN_SCREEN = exports.DASHBOARD_SCREEN = void 0;
exports.DASHBOARD_SCREEN = 'Dashboard';
exports.SIGNIN_SCREEN = 'SignIn';
exports.PROFILE_SCREEN = 'Profile';
exports.SCHEDULE_SCREEN = 'Schedule';
exports.SEARCH_SCREEN = 'Search';
exports.PREVIOUS_SCHEDULES_SCREEN = 'PreviousSchedules';
exports.OFFICERS_METRICS_SCREEN = 'OfficersMetrics';
exports.OFFICER_SCHEDULES_SCREEN = 'OfficerSchedules';
exports.QUALITY_ASSURANCE_SCREEN = 'QualityAssurance';
exports.AUDIT_SCREEN = 'Audit';
exports.AUDITED_CDR_SCREEN = 'AuditedCallDetailRecord';
exports.TICKET_SCREEN = 'Ticket';
exports.TICKET_DETAIL_SCREEN = 'TicketDetail';
exports.CAMPAIGN_SCREEN = 'Campaign';
exports.CAMPAIGNS_SCREEN = 'Campaigns';
exports.CAMPAIGNS_METRICS_SCREEN = 'CampaignsMetrics';
exports.NOTIFICATIONS_SCREEN = 'NotificationsScreen';
exports.MANUAL_CONFIRMATION_SCREEN = 'ManualConfirmation';
